import React from "react";
import { List, ListItem, Box, HStack, VStack, Flex } from "@chakra-ui/react";
import { Link } from "react-router-dom";

function FooterLinksList({ data }) {
  console.log("data", data);
  return (
    <Box mt={3} boxSizing={"border-box"}>
      <List display={"flex"} flexDirection={"column"}>
        <Flex direction="column" alignItems="flex-start" gap={2}>
          {data &&
            data.map((ele) => (
              <ListItem key={ele.id}>
                <Link
                  fontSize={{ base: "12px", md: "16px" }}
                  to={ele.redirectlink}
                  color="white"
                >
                  {ele.title}
                </Link>
              </ListItem>
            ))}
        </Flex>
      </List>
    </Box>
  );
}

export default FooterLinksList;
