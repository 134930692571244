import React from "react";
import {
  Box,
  Text,
  Button,
  VStack,
  HStack,
  Icon,
  Image,
  Flex,
} from "@chakra-ui/react";
import { FaRobot, FaBullhorn, FaHeartbeat } from "react-icons/fa";
// import aiSalsman from "../../Assets/landingpage/banner/aiSalsman.png";
// import aiaddfusion from "../../Assets/landingpage/banner/aiaddfusion.png";
// import aicareconnect from "../../Assets/landingpage/banner/aicareconnect.png";
import vectorlake from "../../Assets/landingpage/banner/vectorlake.png";
import vectorlake2 from "../../Assets/landingpage/banner/vectorlake2.png";
import aiSalsman from "../../Assets/landingpage/banner/agent1.png";
import aiaddfusion from "../../Assets/landingpage/banner/agent2.png";
import aicareconnect from "../../Assets/landingpage/banner/agent3.png";
import AISalesmate from "../../Assets/landingpage/banner/AISalesmate.png";
import AIAdfusion from "../../Assets/landingpage/banner/AIAdfusion.png";
import aiCareConnectLogo from "../../Assets/landingpage/banner/aiCareConnectLogo.png";

const VerticalAgents = ({ carddata }) => {
  console.log("carddata", carddata?.title);
  return (
    <Box
      maxW="65%"
      bgGradient="linear(to-b, #141E30, #243B55)"
      // borderRadius="22px"
      borderTopRadius={"22px"}
      color="white"
      boxShadow="lg"
      h={"60%"}
    >
      {/* <VStack
        align="start"
        spacing="4"
        w={"100%"}
        m={"auto"}
        py={6}
        px={8}
        boxSizing={"border-box"}
      >
        <HStack
          border={"1px solid red"}
          alignItems={"flex-start"}
          spacing="2"
          w={"fit-content"}
        >
        <Image src={AISalesmate} w={"90%"} color="cyan.400" alignSelf="start" />
        <Text fontSize="xl" w={"fit-content"}>
            AI Salesmate
          </Text>
        </HStack>

        <HStack border={"1px solid red"} spacing="2">
        <Image src={AIAdfusion} w={"90%"} color="cyan.400" alignSelf="start" />
        <Text
            fontSize="xl"
            bgGradient="linear(to-r, rgba(72, 140, 239, 1), rgba(213, 72, 178, 1),rgba(255, 255, 255, 1))"
            bgClip={"text"}
          >
            AI AdFusion
          </Text>
        </HStack>

        <HStack border={"1px solid red"} spacing="2">
        <Image
          src={aiCareConnectLogo}
          w={"90%"}
          color="cyan.400"
          alignSelf="start"
        />
        <Text fontSize="xl">AI CareConnect</Text>
        </HStack>
      </VStack> */}

      <VStack
        align="start"
        spacing="8"
        w="100%"
        m="auto"
        py={6}
        px={8}
        pt={8}
        boxSizing="border-box"
      >
        <Image src={AISalesmate} w={"80%"} alignSelf="start" />
        <Image src={AIAdfusion} w={"80%"} alignSelf="start" />
        <Image src={aiCareConnectLogo} w={"80%"} alignSelf="start" />
      </VStack>

      {/* Description Section */}
      <Box
        bgGradient="linear(to-r, #362071, #191B66)"
        // borderRadius="lg"
        borderBottomRadius={"22px"}
        py={4}
        px={6}
        textAlign="start"
        boxSizing={"border-box"}
      >
        <Text fontWeight="semibold" fontSize="md">
          Our Vertical Agents
        </Text>
        <Text fontSize="12px">
          Leverage our vertical agents to deliver domain-specific solutions and
          optimize workflows effortlessly.
        </Text>
        {/* <Box textAlign="right" boxSizing={"border-box"} p={0} m={0}>
          <Button
            fontSize={"xs"} // Small font size
            bgGradient={
              "linear(to-r, rgba(156, 77, 255, 1), rgba(44, 65, 254, 1))"
            }
            _hover={{ bg: "blue.500" }}
            color={"white"}
            borderRadius={"20px"}
            fontWeight={400}
            w={"fit-content"}
            px={3} // Reduced horizontal padding
            py={1} // Reduced vertical padding
          >
            Know more
          </Button>
        </Box> */}
      </Box>
    </Box>
  );
};

export default VerticalAgents;
