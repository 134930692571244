import { Box } from "@chakra-ui/react";
import React from "react";
import CompniesSlider from "../../components/Home/FeaturedLogos";
import Usp from "./Usp";
import IndustrisWork from "./IndustrisWork";
import VectorDataLake from "./VectorDataLake";
import BannerComp2 from "./BannerComp2";
import HomePage from "../HomePage/Home";
import CarouselComponent from "./SlidingBanner";
import Carousel from "./Swiper";
import VerticalAgents from "./VerticalComp";
import ring from "../../Assets/landingpage/banner/ring.png";
import Footer from "../../components/footer/Footer";
import FooterMain from "../../components/footer/FooterMain";
import ContactPage from "../demo/Demo";

// import SwiperFn from "./Swiper";

function Home() {
  // testing web hooks
  return (
    <Box bg="black" >
      {/* <SlidingBanner/> */}
      {/* <BannerCompo1 /> */}
      {/* <HomePage/> */}
      <Box
        bg="linear-gradient(to top right,#070821 , black,black ,black,black ,black,black ,black,black,#070821 ,#19113F, #19113F)"
        border={"1px solid black"}
        position={"relative"}
        zIndex={1}
      >
        <BannerComp2 />
        <CompniesSlider />
        <Box
          as="img"
          src={ring}
          alt="Animated Ring"
          position="absolute"
          bottom="97"
          width="180px"
          height="220px"
          zIndex="1"
        />
      </Box>

      {/* <VideoSection /> */}
        <Usp />

      {/* <VectorDataLake /> */}
      {/* <IndustrisWork /> */}
      <ContactPage />
      {/* <FooterMain /> */}
    </Box>
  );
}

export default Home;
