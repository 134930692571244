import React from "react";
import { Box, Heading, IconButton, Image, Text } from "@chakra-ui/react";
import Slider from "react-slick";
import { ArrowBackIcon, ArrowForwardIcon } from "@chakra-ui/icons";
import VerticalAgents from "./VerticalComp";
import vectorlake from "../../Assets/landingpage/banner/vectorlake.png";
import vectorlake2 from "../../Assets/landingpage/banner/vectorlake2.png";
import waveflow from "../../Assets/landingpage/banner/waveflow.png";
import VerticalComp2 from "./VerticalComp2";
import VerticalComp3 from "./VericalComp3";
const Carousel = () => {
  const sliderSettings = {
    // dots: true,
    infinite: true,
    speed: 300,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true, // Enable autoplay
    autoplaySpeed: 3000, // Slide duration in milliseconds
    arrows: false,
    vertical: true, // Enable vertical sliding
    verticalSwiping: true, // Ensure smooth vertical swiping
  };

  let data = [
    { title: "aisals", img1: "" },
    { title: "Waveflow", img1: "", img2: waveflow },
    { title: "VectorLake", img1: vectorlake, img2: vectorlake2 },
  ];

  const sliderRef = React.useRef();

  return (
    <Box position="relative" w="100%">
      {/* Slider */}
      <Slider ref={sliderRef} {...sliderSettings}>
        {data.map((item, index) => (
          <Box key={index} position="relative" h={{ base: "50%", md: "96" }}>
            {item.title == "aisals" && <VerticalAgents />}
            {item.title == "Waveflow" && <VerticalComp2 />}
            {item.title == "VectorLake" && <VerticalComp3 />}
          </Box>
        ))}
      </Slider>

      {/* Navigation Arrows */}
      {/* <IconButton
        aria-label="Previous"
        icon={<ArrowBackIcon />}
        position="absolute"
        top="10px"
        left="50%"
        transform="translateX(-50%)"
        zIndex="10"
        bg="whiteAlpha.800"
        _hover={{ bg: "white" }}
        onClick={() => sliderRef.current.slickPrev()}
      /> */}
      {/* <IconButton
        aria-label="Next"
        icon={<ArrowForwardIcon />}
        position="absolute"
        bottom="10px"
        left="50%"
        transform="translateX(-50%)"
        zIndex="10"
        bg="whiteAlpha.800"
        _hover={{ bg: "white" }}
        onClick={() => sliderRef.current.slickNext()}
      /> */}
    </Box>
  );
};

export default Carousel;
