import { Box, Text } from "@chakra-ui/react";
import React from "react";

function Resource() {
  return (
    <>
      <Box
        w={"100%"}
        h={"100vh"}
        textAlign={"center"}
        objectPosition={"center"}
        alignItems={"center"}
        fontSize={"xxx-large"}
        background={"black"}
      >
        <Text
          color={"white"}
          bgGradient="linear(to-r, #151920, #5AB9EA)"
          bgClip="text"
        >
          Coming soon ...
        </Text>
      </Box>

    </>
  );
}

export default Resource;
