import React from "react";
import { Box, Flex } from "@chakra-ui/react";
import AllRoutes from "./pages/AllRoutes";
import Navbar from "./components/Navbar";
import Footer from "./components/footer/Footer";
import FooterMain from "./components/footer/FooterMain";
import Usp from "./pages/Home/Usp";

// THEJ1ZBYZ9TDVT7ANBXXJHMR

function App() {
  return (
    <Flex w={"100%"} direction="column" minH="100vh">
      <Navbar />
      <AllRoutes />

      <FooterMain />
    </Flex>
  );
}

export default App;
