import React, { useState } from "react";
import {
  Box,
  Button,
  Text,
  VStack,
  HStack,
  Image,
  Heading,
  useBreakpointValue,
} from "@chakra-ui/react";
import EnterpriseReady from "../../Assets/landingpage/banner/EnterpriseReady.png";
import rightArrow from "../../Assets/landingpage/banner/rightArrow.png";
import roundBtn from "../../Assets/landingpage/banner/roundBtn.png";
import ring from "../../Assets/landingpage/banner/ring.png";

// import VerticalCarousel from "./VericalCarousl";
// import CarouselComponent from "./VericalCarousl";
import VerticalAgents from "./VerticalComp";
import CarouselComponent from "./VericalCarousl";
import Carousel from "./Swiper";
import SlidingBanner from "./SlidingBanner";

function BannerComp2() {
  const isLaptopView = useBreakpointValue({ base: false, lg: true });

  return (
    <HStack
      bg="linear-gradient(to top right, black, black ,#19113F, #19113F, black, black)"
      position="relative"
      flexDirection={{ base: "column", lg: "row" }}
      spacing={0}
    >
      {/* Left Content */}
      <Box
        w={{ base: "100%", lg: "50%" }}
        color="rgba(255, 255, 255, 1)"
        pl={{ base: "5%", lg: "8%" }}
        pt="5%"
        pb="5%"
        pr={{ base: "5%", lg: "15px" }}
        boxSizing="border-box"
        zIndex={2}
        position="relative"
        backdropFilter="blur(2px)"
        textAlign={{ base: "center", lg: "left" }}
      >
        <VStack spacing={6} align={{ base: "center", lg: "start" }}>
          <Button
            color="white"
            bg="transparent"
            border="1px solid rgba(63, 63, 63, 1)"
            borderRadius="lg"
            boxSizing="border-box"
          >
            <Image src={EnterpriseReady} mr="10px" /> Enterprise Ready
          </Button>

          <Text
            fontSize={{ base: "2xl", md: "3xl", lg: "xxx-large" }}
            fontWeight={500}
            lineHeight={1.1}
          >
            Unlock Innovation
            <br />
            through our
            <br />
            Agentic Framework
          </Text>

          <Text w={{ base: "90%", lg: "80%" }}>
            Streamline workflows, enhance efficiency, and drive intelligent
            decision-making like never before
          </Text>

          <HStack
            spacing={4}
            justifyContent={{ base: "center", lg: "flex-start" }}
            flexWrap="wrap"
          >
            <Button
              color="rgba(255, 255, 255, 1)"
              borderRadius="30px"
              bgGradient="linear(to-r, rgba(156, 77, 255, 1), rgba(44, 65, 254, 1))"
            >
              Explore Studio
            </Button>
            <Button
              h={"45px"}
              w={"45px"}
              borderRadius="50%"
              border="1px solid rgba(63, 63, 63, 1)"
              bg="transparent"
            >
              <Image src={rightArrow} />
            </Button>
          </HStack>

          <HStack
            boxSizing="border-box"
            w="100%"
            mt="30px"
            bg="transparent"
            spacing={{ base: 2, md: 4 }}
            justifyContent={{ base: "center", lg: "flex-start" }}
            flexWrap="wrap"
          >
            {[
              "Conversational Search",
              "Enterprise Assistant",
              "Zero Touch",
            ].map((item, idx) => (
              <Button
                bg="transparent"
                border="1px solid rgba(63, 63, 63, 1)"
                color="white"
                key={idx}
                p={4}
                boxSizing="border-box"
                textAlign="center"
              >
                {item.split(" ").map((word, wordIdx) => (
                  <>
                    {word}
                    {wordIdx < item.split(" ").length - 1 && <br />}
                  </>
                ))}
              </Button>
            ))}
          </HStack>
        </VStack>
      </Box>

      {/* Right Content */}
      <Box
        w={{ base: "100%", lg: "45%" }}
        p={{ base: "20px", lg: "30px" }}
        boxSizing="border-box"
        position="relative"
        zIndex={10}
      >
        {isLaptopView ? <Carousel /> : <SlidingBanner />}
      </Box>
    </HStack>
  );
}

export default BannerComp2;
