import React from "react";
import { List, ListItem, Image, Link, Box, HStack } from "@chakra-ui/react";
import insta from "../../Assets/landingpage/footer/insta.png";
import facbook from "../../Assets/landingpage/footer/facebook.png";
import linkdine from "../../Assets/landingpage/footer/linkdine.png";
import Twitter from "../../Assets/landingpage/footer/twitter.png";

const data = [
  // {
  //   icon: insta,
  //   redirect:"https://twitter.com/rrelan2?lang=en"
  // },
  // {
  //   icon: facbook,
  //   redirect:"https://twitter.com/rrelan2?lang=en"
  // },
  {
    icon: Twitter,
    redirect:"https://twitter.com/rrelan2?lang=en"
  },
  {
    icon: linkdine,
    redirect:"https://www.linkedin.com/in/ranjan-relan/"
  },
];




function SocialLink() {
  return (
    <Box boxSizing={"border-box"}>
      <List display={"flex"} flexDirection={"column"}>
        <HStack spacing={6}>
          {data &&
            data.map((ele) => (
              <ListItem>
                <Link href={ele.redirect} isExternal>
                  <Image
                    src={ele?.icon}
                    alt="linkdine"
                    display="inline"
                    w={"35px"}
                  />
                </Link>
              </ListItem>
            ))}
        </HStack>
      </List>
    </Box>
  );
}

export default SocialLink;
