export const footerData = {
  //   Product: [
  //     { title: "Features", redirectlink: "#" },
  //     { title: "FAQ", redirectlink: "#" },
  //     { title: "Pricing", redirectlink: "/pricing" },
  //   ],
  Company: [
    { title: "Home", redirectlink: "/" },
    { title: "About us", redirectlink: "/" },
    { title: "Features", redirectlink: "#" },
  ],
  Pricing: [
    { title: "Waveflow", redirectlink: "/waveflow" },
    { title: "Conversational Search", redirectlink: "/Conversational-Search" },
    { title: "Conversational AI", redirectlink: "/Conversational-AI" },
  ],
  //   Legal: [
  //     { title: "Privacy", redirectlink: "#" },
  //     { title: "Terms", redirectlink: "#" },
  //     { title: "Security", redirectlink: "#" },
  //   ],
};

