import React from "react";
import {
  Box,
  Text,
  Button,
  VStack,
  HStack,
  Icon,
  Image,
  Flex,
} from "@chakra-ui/react";
import { FaRobot, FaBullhorn, FaHeartbeat } from "react-icons/fa";
import aiSalsman from "../../Assets/landingpage/banner/aiSalsman.png";
import aiaddfusion from "../../Assets/landingpage/banner/aiaddfusion.png";
import aicareconnect from "../../Assets/landingpage/banner/aicareconnect.png";
import vectorlake from "../../Assets/landingpage/banner/vectorlake.png";
import vectorlake2 from "../../Assets/landingpage/banner/vectorlake2.png";
import waveflow from "../../Assets/landingpage/banner/waveflow.png";
import waveflow2 from "../../Assets/landingpage/banner/waveflowmain.png";
import VectorLakeWithLogo from "../../Assets/landingpage/banner/WaveflowWithLogo.png";
const VerticalComp2 = () => {
  return (
    <Box
      maxW="65%"
      bgGradient="linear(to-b, #141E30, #243B55)"
      borderRadius="22px"
      
      color="white"
      boxShadow="lg"
      h={"70%"}
    >
      <Box
        w="100%"
        h="80%"
        backgroundImage={`url(${waveflow})`}
        backgroundPosition="bottom"
        backgroundSize="100% 100%"
        backgroundRepeat="no-repeat"
        boxSizing="border-box"
        flexDirection={{ base: "column", md: "row" }}
      >
        <Flex
          justifyContent="center"
          alignItems="center"
          h="80%" // Ensure Flex takes full height to center vertically
          w="100%" // Ensure Flex takes full width to center horizontally
        >
          <HStack alignItems={"center"} spacing="2" w={"fit-content"}>
            <Image src={VectorLakeWithLogo} color="cyan.400" />
            {/* <Text
              fontFamily={"Pixelated Elegance"}
              fontSize="30px"
              w={"fit-content"}
            >
              WaveFlow
            </Text> */}
          </HStack>
        </Flex>
      </Box>

      {/* Description Section */}
      <Box
        bgGradient="linear(to-r, #362071, #191B66)"
        // borderRadius="lg"
        borderBottomRadius={"22px"}
        py={4}
        px={6}
        textAlign="start"
        boxSizing={"border-box"}
      >
        <Text fontWeight="semibold" fontSize="md">
          Our Vertical Agents
        </Text>
        <Text fontSize="12px">
          Leverage our vertical agents to deliver domain-specific solutions and
          optimize workflows effortlessly.
        </Text>
        {/* <Box textAlign="right" boxSizing={"border-box"} p={0} m={0}>
          <Button
            fontSize={"xs"} // Small font size
            bgGradient={
              "linear(to-r, rgba(156, 77, 255, 1), rgba(44, 65, 254, 1))"
            }
            _hover={{ bg: "blue.500" }}
            color={"white"}
            borderRadius={"20px"}
            fontWeight={400}
            px={3} // Reduced horizontal padding
            py={0} // Reduced vertical padding
          >
            Know more
          </Button>
        </Box> */}
      </Box>
    </Box>
  );
};

export default VerticalComp2;
