import { Box, Grid, GridItem, Text } from "@chakra-ui/react";
import React from "react";
import UspBox from "../../components/BoxStyles/UspBox";
import { features } from "./BannerData";
import UspTitle from "./UspTitle";
import uspring from "../../Assets/landingpage/usp/uspring.png";

function Usp() {
  return (
    <Box
      boxSizing={"border-box"}
      w={"100%"}
      m={"auto"}
      // mb={{ base: "75px", md: "125px" }}
      color={"white"}
      bg="linear-gradient(to top right, black,black ,#19113F, #19113F,black,black)"
      position={"relative"}
      zIndex={10}
      border="1px solid black"
    >
      <Box
        position={"relative"}
        zIndex="2"
        bg={"transparent"}
        w={"85%"}
        margin={"auto"}
        mt={"50px"}
      >
        <UspTitle />
      </Box>

      <Grid
        templateColumns={{
          base: "repeat(1, 1fr)", // For small screens (mobile), 1 column
          sm: "repeat(2, 1fr)", // For slightly larger screens (small tablets), 2 columns
          md: "repeat(3, 1fr)", // For medium screens (tablets), 3 columns
          lg: "repeat(3, 1fr)", // For large screens (desktops), 4 columns
        }}
        w={"85%"}
        margin={"auto"}
        gap={6}
        boxSizing="border-box"
        mb={"100px"}
        position={"relative"}
        zIndex="2"
        backdropFilter="blur(5px)"
        mt={{ base: "20px", sm: "30px", md: "45px" }} // Adjust margin-top for different screen sizes
      >
        {features &&
          features.map((usp, index) => (
            <GridItem
              key={index}
              w="100%"
              margin={"auto"}
              position={"relative"}
              zIndex="2"
            >
              <UspBox usp={usp} index={index} />
            </GridItem>
          ))}
      </Grid>
      <Box
        as="img"
        src={uspring}
        alt="Animated Ring"
        position="absolute"
        width="200px"
        height="300px"
        zIndex="1"
        top={0}
        right={0}
      />
    </Box>
  );
}

export default Usp;
