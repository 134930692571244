import { Box } from "@chakra-ui/react";
import React from "react";
import { Route, Routes } from "react-router-dom";
import Home from "./Home/Home";
import Navbar from "../components/Navbar";
import Pricing from "./Pricing/Pricing";
import JobDescription from "./Job/JobDescription";
import JobApplyPage from "./Job/JobApplyPage";
import Partners from "./partner/Partners";
import Industries from "./Industries/Industries";
import Solution from "./Solution/Solution";
import PricingMainPage from "./Pricing/darkmode/PricingMainPage";
import Resource from "./Resource/Resource";
import WaveMain from "./Pricing/WaveMain";
import ConversationAiMain from "./Pricing/ConversationAiMain";
import EnterPriseMain from "./Pricing/EnterPriseMain";

function AllRoutes() {
  return (
    <Box>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/pricing" element={<PricingMainPage />} />
        <Route path="/career" element={<JobDescription />} />
        <Route path="/jobAplyPage" element={<JobApplyPage />} />
        <Route path="/partners" element={<Partners />} />
        <Route path="/industries" element={<Industries />} />
        <Route path="/resource" element={<Resource />} />

        <Route path="/solution" element={<Solution />} />
        <Route path="/demo" element={<Solution />} />
        <Route path="/waveflow" element={<WaveMain />} />
        <Route path="/Conversational-Search" element={<EnterPriseMain />} />
        <Route path="/Conversational-AI" element={<ConversationAiMain />} />
      </Routes>
    </Box>
  );
}

export default AllRoutes;
